<template>
  <div id="modify_login_pwd">
    <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="100px" class="demo-ruleForm">
      <el-form-item v-if="auth_state === 0" label="上传身份证人像面照片（仅支持jpg.png.格式照片）：" class="id_card_pic"  prop="id_card_pic">
        <div class="id_card_pic_label" slot="label">上传身份证人像面照片 <span>（仅支持jpg.png.格式照片）</span></div>
        <div class="id_card_pic_ground">
          <div id="front">
            <el-upload
              v-loading="loading"
              customClass="rName-uploader"
              action
              :auto-upload="false"
              :show-file-list="false"
              :on-change="frontAvatarUpload">
              <img v-if="frontUrl" :src="frontUrl" class="rName">
              <i v-else class="el-icon-plus rName-uploader-icon rName-uploader-icon_front "></i>
              <el-button size="small" type="primary" class="rName-upload-btn">上传人像面</el-button>
            </el-upload>
          </div>
        </div>
        <div id="tips">点击选择图片（需小于5M）</div>
      </el-form-item>
      <el-form-item v-show="showInput || auth_state !== 0" label="真实姓名："  prop="r_name">
        <el-input placeholder="上传身份证自动识别" v-model="infoForm.r_name" disabled></el-input>
      </el-form-item>
      <el-form-item v-show="showInput || auth_state !== 0" label="身份证号："  prop="id_num">
        <el-input placeholder="上传身份证自动识别" v-model="infoForm.id_num" disabled></el-input>
      </el-form-item>
    </el-form>
    <div v-if="auth_state === 1" style="background-color: #f2f2f3 !important;" disabled class="submit_btn" >已实名</div>
    <div v-else class="submit_btn" @click="submitForm('infoForm')">立即认证</div>

  </div>
</template>
<script>
import {apiUserVerifed,apiUploadAuth,apiuserInfo} from '@/request/API'
import * as imageConversion from 'image-conversion'


export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      showInput:false,
      loading: false,
      //身份证 正
      frontUrl: '',
      //实名状态
      auth_state: 0,
      //表单信息
      infoForm:{
        r_name:this.$store.state.userData.cert_name,
        id_num:this.$store.state.userData.cert_id,
      },
      //表单验证
      rules: {
        r_name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { pattern: /^([\u4e00-\u9fa5]{1,20}|[a-zA-Z\.\s]{1,20})$/, message: '请输入真实姓名'}
        ],
        id_num: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入真确的身份证号码', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    /**
     * 提交表单
     * @param formName 表单名称
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiUserVerifed({
            cert_name:this.infoForm.r_name,
            cert_id:this.infoForm.id_num,
          }).then(res=>{
            this.$message({
              center:true,
              message:"提交成功",
              type:"success"
            })
            this.getBasicInfo()
            setTimeout( ()=>{
              if (this.$route.query.Rurl) {
                this.$router.go(-1)
              }
            },2000)
          }).catch(err=>{
            this.$message({
              center:true,
              message:err.msg,
              type:"error"
            })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    /**
     * 上传身份证图片
     * @param file 图片信息
     * @returns {boolean} 图片格式是否符合要求
     */
  async frontAvatarUpload(file) {
      this.loading = true
      const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
        this.loading = false
        return
      }
     let newFile = await imageConversion.compressAccurately(file.raw, {
        size: 1024,
        type: 'image/jpeg'
      })
      this.$getBase64(newFile).then(res => {
        apiUploadAuth({
          file:res
        }).then(res=>{
          this.$message({
            type:'success',
            message:'验证成功',
            center:true
          })
          this.infoForm.r_name = res.data.cert_name
          this.infoForm.id_num = res.data.cert_id
          this.frontUrl = URL.createObjectURL(file.raw)
          this.loading = false
          this.showInput = true

        }).catch(err=>{
          this.$message({
            type:"error",
            message:err.msg,
            center:true
          })
          this.loading = false
        })
      });

      return isJPG ;
    },
    getBasicInfo(){
      apiuserInfo({
        action:'verified'
      }).then(res=>{
        this.auth_state = res.data.auth_state
        this.infoForm.id_num = res.data.cert_id
        this.infoForm.r_name = res.data.cert_name
      })
    }
  },
  mounted () {
    this.getBasicInfo()
  },
  watch: {

  },
  computed: {

  }
}
</script>

<style>
.rName-uploader .el-upload {
  height: 116px !important;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  /*overflow: hidden;*/
}
.rName-upload-btn{
  border-radius: 0 0 6px 6px;
  /*position: absolute;*/
  /*top: 114px;*/
  /*left: -1px;*/
  width: 173px;
  height: 40px;
  background: #283748 !important;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  padding: 0;
  line-height: 40px;
  text-align: center;
}
.rName-upload-btn:hover{
  background: #283748 !important;
}
.rName-uploader .el-upload:hover {
  border-color: #FCAD13;
}
.rName-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 173px;
  height: 114px;
  line-height: 114px;
  text-align: center;
}
.rName-uploader-icon_front{
  background: url("../../../../assets/image/PersonalCenter/PersonalInfo/front.png");
}
.rName-uploader-icon_behind{
  background: url("../../../../assets/image/PersonalCenter/PersonalInfo/behind.png");
}
.rName {
  width: 173px;
  height: 114px;
  display: block;
}
</style>

<style lang='less' scoped>
/deep/.el-upload{
  display: flex;
  flex-direction: column;
}
#modify_login_pwd{
  padding: 60px 0 0 256px;
  display: flex;
  flex-direction: column;
  //align-items: center;
  /deep/.el-input{
    width: 250px;
  }
  /deep/.el-input__inner{
    width: 250px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #8F8F8F;
  }
  /deep/.is-error .el-input__inner{
    border-color: #F56C6C;
  }
  /deep/.el-form-item__label{
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    padding: 0;
  }
  .id_card_pic{
    display: flex;
    flex-direction: column;
  }
  .id_card_pic /deep/.el-form-item__label{
    width: 355px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    .id_card_pic_label{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #5B5B5B;
      span{
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #8F8F8F;
      }
    }
  }
  .id_card_pic /deep/.el-form-item__content{
    //margin: 0 !important;
    .id_card_pic_ground{
      height: 116px !important;
      display: flex;
      flex-direction: row;
      #front{
        margin:  0 0 0 12px;
      }
      #behind{
        margin:  0 0 0 20px;
      }
      #handHeld{
        margin:  0 0 0 20px;
      }
    }
    #tips{
      margin: 50px 0 0 12px;
      font-size: 14px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
      line-height: 20px;
    }
  }
  .submit_btn{
    margin: 29px 0 0 82px;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #434ED6;
    border-radius: 28px;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
  }

}
</style>
